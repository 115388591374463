import React from "react";
import {Formik, Form} from "formik";
import {Button, Stack} from "@chakra-ui/react";
import {Input, PhoneInput, TextArea} from "@onemind-services-llc/ui-components";
import {BUTTON_VARIANTS} from "@/theme/components/button";
import {CONTACT_FORM_CONSTANTS} from "../contactForm.constants";
import {initialValues, validationSchema} from "./contactForm.config";
import {useHandleContactFormSubmission} from "./contactForm.hooks";

const ContactUsForm = () => {
  const {handleSubmit} = useHandleContactFormSubmission();
  return (
    <Stack
      boxShadow={"lg"}
      borderRadius={"8px"}
      px={{base: 4, sm: 8}}
      py={{base: 4, sm: 8, md: 12}}
      w={"full"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => {
          const {errors, touched, getFieldProps, handleChange, isSubmitting} = formik;
          return (
            <Form>
              <Stack spacing={4} w={"full"}>
                <Input
                  {...getFieldProps("first_name")}
                  errorMessage={errors.first_name}
                  isInvalid={Boolean(errors.first_name && touched.first_name)}
                  label={CONTACT_FORM_CONSTANTS.FIRST_NAME}
                />
                <Input
                  {...getFieldProps("last_name")}
                  errorMessage={errors.last_name}
                  isInvalid={Boolean(errors.last_name && touched.last_name)}
                  label={CONTACT_FORM_CONSTANTS.LAST_NAME}
                />
                <Input
                  {...getFieldProps("company_name")}
                  errorMessage={errors.company_name}
                  isInvalid={Boolean(errors.company_name && touched.company_name)}
                  label={CONTACT_FORM_CONSTANTS.COMPANY_NAME}
                />
                <Input
                  {...getFieldProps("email")}
                  errorMessage={errors.email}
                  isInvalid={Boolean(errors.email && touched.email)}
                  label={CONTACT_FORM_CONSTANTS.EMAIL}
                />
                <PhoneInput
                  {...getFieldProps("phone_number")}
                  errorMessage={errors.phone_number}
                  isInvalid={Boolean(errors.phone_number && touched.phone_number)}
                  label={CONTACT_FORM_CONSTANTS.PHONE_NO}
                  setCountryCode={(code) =>
                    handleChange({target: {name: "country_code", value: code}})
                  }
                />
                <Input
                  {...getFieldProps("subject")}
                  errorMessage={errors.subject}
                  isInvalid={Boolean(errors.subject && touched.subject)}
                  label={CONTACT_FORM_CONSTANTS.SUBJECT}
                />
                <TextArea
                  {...getFieldProps("message")}
                  errorMessage={errors.message}
                  isInvalid={Boolean(errors.message && touched.message)}
                  label={CONTACT_FORM_CONSTANTS.MESSAGE}
                />
                <Button
                  isLoading={isSubmitting}
                  loadingText="Sending"
                  size="md"
                  type="submit"
                  variant={BUTTON_VARIANTS.BRAND}
                >
                  {CONTACT_FORM_CONSTANTS.SEND}
                </Button>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};

export default ContactUsForm;
