import {z} from "zod";
import {VALIDATION_CONSTANTS} from "./validations.constants";

export const phoneNumberSchema = z.object({
  phone_number: z.string({required_error: VALIDATION_CONSTANTS.PHONE_REQUIRED}),
});

export const emailStringSchema = z
  .string({required_error: VALIDATION_CONSTANTS.EMAIL_REQUIRED})
  .email(VALIDATION_CONSTANTS.EMAIL_INVALID);

export const emailSchema = z.object({
  email: emailStringSchema,
});

export const firstNameSchema = z
  .string({required_error: VALIDATION_CONSTANTS.FIRST_NAME_REQUIRED})
  .regex(/^[a-zA-Z]+$/, VALIDATION_CONSTANTS.FIRST_NAME_INVALID)
  .max(50, VALIDATION_CONSTANTS.FIRST_NAME_LONG);

export const lastNameSchema = z
  .string({required_error: VALIDATION_CONSTANTS.LAST_NAME_REQUIRED})
  .regex(/^[a-zA-Z]+$/, VALIDATION_CONSTANTS.LAST_NAME_INVALID)
  .max(50, VALIDATION_CONSTANTS.LAST_NAME_LONG);

export const userSchema = z.object({
  first_name: firstNameSchema,
  last_name: lastNameSchema,
});
