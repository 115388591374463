export const VALIDATION_CONSTANTS = {
  PHONE_REQUIRED: "Please enter your mobile number.",
  PHONE_INVALID: "Please enter valid mobile number.",
  EMAIL_REQUIRED: "Please enter your email.",
  EMAIL_INVALID: "Please enter valid email.",
  FIRST_NAME_REQUIRED: "Please enter your first name.",
  FIRST_NAME_INVALID: "Please enter valid first name.",
  FIRST_NAME_LONG: "First name cannot be longer than 50 characters",
  LAST_NAME_REQUIRED: "Please enter your last name.",
  LAST_NAME_INVALID: "Please enter valid last name.",
  LAST_NAME_LONG: "Last name cannot be longer than 50 characters",
};
