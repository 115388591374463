import {
  FaEnvelopeOpenText,
  FaLocationArrow,
  FaMailBulk,
  FaPhoneAlt,
} from "@onemind-services-llc/react-icons-ng/fa";

export const CONTACT_FORM_CONSTANTS = {
  COMPANY_NAME: "Company Name",
  CONTACT_US: "Contact Us",
  CONTACT_US_SHORT_NOTE:
    "We Would Be Happy To Meet You And Learn All About Your Business",
  EMAIL: "Your Email (required)",
  ERROR_MESSAGE: "Your message cannot be sent. Please try again.",
  FIRST_NAME: "First Name(required)",
  INDIA: "India",
  LAST_NAME: "Last Name (required)",
  MESSAGE: "Your Message (required)",
  MESSAGE_REQUIRED: "Please enter message.",
  PHONE_NO: "Phone Number (required)",
  SEND: "Send",
  SUBJECT: "Subject (required)",
  SUBJECT_REQUIRED: "Please enter subject.",
  SUCCESS_MESSAGE: "Your message has been sent. We will get back to you shortly.",
  UNITED_STATES: "United States",
};

export const CONTACT_LIST = [
  {
    icon: FaEnvelopeOpenText,
    label: "info@onemindservices.com",
    path: "mailto:info@onemindservices.com",
  },
  {
    icon: FaMailBulk,
    label: "support@onemindservices.com",
    path: "mailto:support@onemindservices.com",
  },
  {icon: FaPhoneAlt, label: "1-408-540-6771", path: "tel:1-408-540-6771"},
  {
    isExternal: true,
    icon: FaLocationArrow,
    label: "11501 Dublin Bvld, Suite#200 Dublin CA 94568",
    path: "https://maps.google.com?q=11501 Dublin Bvld, Suite#200 Dublin CA 94568",
  },
];
export const CONTACT_LIST_TWO = [
  {
    icon: FaMailBulk,
    label: "ind@onemindservices.com",
    path: "mailto:ind@onemindservices.com",
  },
  {icon: FaPhoneAlt, label: "+91 9958089890", path: "tel:+919958089890"},
  {
    isExternal: true,
    icon: FaLocationArrow,
    label: "08th Floor, Magnum Tower, Sector 58, Gurugram, 122001 Haryana, India",
    path: "https://maps.google.com?q=08th Floor, Magnum Tower, Sector 58, Gurugram, 122001 Haryana, India",
  },
];
