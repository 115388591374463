import React from "react";
import Image from "next/image";
import Link from "next/link";
import {
  AspectRatio,
  Box,
  Divider,
  Highlight,
  HStack,
  Heading,
  Icon,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import {HEADING_VARIANTS} from "@/theme/components/heading";
import {TEXT_VARIANTS} from "@/theme/components/text";
import ContactUs from "public/contactForm/contact-us.jpeg";
import {
  CONTACT_FORM_CONSTANTS,
  CONTACT_LIST,
  CONTACT_LIST_TWO,
} from "./contactForm.constants";
import Form from "./components/form";

const ContactForm = () => {
  return (
    <SimpleGrid columns={{base: 1, md: 2}} spacing={{base: 6, md: 8}}>
      <Stack spacing={4}>
        <Box alignSelf={{sm: "center"}} bg={"#F5F5F5"} borderRadius="full" p={4}>
          <AspectRatio
            position={"relative"}
            ratio={1}
            transition={"all 0.3s ease"}
            width={{base: "full", sm: "350px", md: "400px"}}
            _hover={{transform: "scale(1.08)"}}
          >
            <Image
              alt="Cloud-Application"
              fill
              src={ContactUs}
              style={{
                borderRadius: "50%",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </AspectRatio>
        </Box>
        <Stack>
          <Heading color={"brand.100"} variant={HEADING_VARIANTS.SUBTITLE3}>
            {CONTACT_FORM_CONSTANTS.CONTACT_US}
          </Heading>
          <Divider width={"50px"} borderBottomWidth={"2px"} borderBottomColor={"black"} />
          <Heading color={"brand.100"} fontWeight={"400"}>
            <Highlight query={"Business"} styles={{color: "black", fontWeight: "700"}}>
              {CONTACT_FORM_CONSTANTS.CONTACT_US_SHORT_NOTE}
            </Highlight>
          </Heading>
        </Stack>
        <Stack>
          <Heading
            color={"brand.200"}
            textAlign={"center"}
            variant={HEADING_VARIANTS.SUBTITLE3}
          >
            {CONTACT_FORM_CONSTANTS.UNITED_STATES}
          </Heading>
          {CONTACT_LIST.map((contact, index) => {
            return (
              <HStack key={index}>
                <Icon as={contact.icon} color={"brand.100"} fontSize={"20px"} />
                <Text
                  as={Link}
                  href={contact.path}
                  target={contact.isExternal ? "_blank" : ""}
                  variant={TEXT_VARIANTS.BODY1}
                  _hover={{color: "brand.100", textDecor: "underline"}}
                >
                  {contact.label}
                </Text>
              </HStack>
            );
          })}
        </Stack>
        <Stack>
          <Heading
            color={"brand.200"}
            textAlign={"center"}
            variant={HEADING_VARIANTS.SUBTITLE3}
          >
            {CONTACT_FORM_CONSTANTS.INDIA}
          </Heading>
          {CONTACT_LIST_TWO.map((contact, index) => {
            return (
              <HStack key={index}>
                <Icon as={contact.icon} color={"brand.100"} fontSize={"20px"} />
                <Text
                  as={Link}
                  href={contact.path}
                  target={contact.isExternal ? "_blank" : ""}
                  variant={TEXT_VARIANTS.BODY1}
                  _hover={{color: "brand.100", textDecor: "underline"}}
                >
                  {contact.label}
                </Text>
              </HStack>
            );
          })}
        </Stack>
      </Stack>
      <Form />
    </SimpleGrid>
  );
};

export default ContactForm;
