import {z} from "zod";
import {toFormikValidationSchema} from "zod-formik-adapter";
import {userSchema, phoneNumberSchema, emailSchema} from "@/validations/validationSchema";
import {CONTACT_FORM_CONSTANTS} from "../contactForm.constants";

const contactFormSchema = z.object({
  company_name: z.string().optional(),
  country_code: z.string(),
  message: z.string({required_error: CONTACT_FORM_CONSTANTS.MESSAGE_REQUIRED}),
  subject: z.string({required_error: CONTACT_FORM_CONSTANTS.SUBJECT_REQUIRED}),
});

const schema = userSchema
  .merge(phoneNumberSchema)
  .merge(emailSchema)
  .merge(contactFormSchema);

export type ContactFormType = z.infer<typeof schema>;

export const validationSchema = toFormikValidationSchema(schema);

export const initialValues: ContactFormType = {
  company_name: "",
  country_code: "+1",
  email: "",
  first_name: "",
  last_name: "",
  message: "",
  phone_number: "",
  subject: "",
};
