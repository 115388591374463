import {FormikHelpers} from "formik";
import {useErrorToast, useSuccessToast} from "@onemind-services-llc/ui-components";
import axios from "axios";
import {CONTACT_FORM_CONSTANTS} from "../contactForm.constants";
import {ContactFormType, initialValues} from "./contactForm.config";

export const useHandleContactFormSubmission = () => {
  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const handleSubmit = (
    values: ContactFormType,
    formikHelpers: FormikHelpers<ContactFormType>
  ) => {
    const {setErrors, setSubmitting, setTouched, setValues} = formikHelpers;
    const {country_code, phone_number, ...rest} = values;
    const payload = {
      ...rest,
      phone_number: `${country_code}${phone_number}`,
    };
    axios
      .post("/api/sendgrid", payload, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        successToast({
          description: response?.data?.message || CONTACT_FORM_CONSTANTS.SUCCESS_MESSAGE,
          title: "Message sent.",
        });
        setTouched({});
        setValues(initialValues);
      })
      .catch((error) => {
        setErrors(error?.response?.data?.field_errors || {});
        errorToast({
          description:
            error?.response?.data?.message || CONTACT_FORM_CONSTANTS.ERROR_MESSAGE,
          title: "Error occurred.",
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return {handleSubmit};
};
